import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { PasswordResponse } from '../models/password-response.model';
import { Observable } from 'rxjs';
import { Companies } from '../models/companies.model';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService{
    private endpoit: string = environment.server + 'api/Companies/';

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }

    getCompanies():Observable<Companies[]>{
        return this.httpClient
        .get<Companies[]>(this.endpoit + 'GetAllCompanies')
        .pipe();
    }

    getCompanyById(companyId:number):Observable<Companies>{
        return this.httpClient
            .get<Companies>(this.endpoit + 'GetCompanyById/'+companyId)
            .pipe();
    }
    createCompany(company:any){
        return this.httpClient
        .post<Companies>(this.endpoit + 'CreateCompany',
            company
        )
        .pipe();
    }
    updateCompany(companyId:string,company:any):Observable<Companies>{
        return this.httpClient
            .put<Companies>(this.endpoit + 'UpdateCompany/'+companyId,
                company
            )
            .pipe();
    }
    deleteCompany(companyId:string):Observable<Companies>{
        return this.httpClient
            .delete(this.endpoit + 'DeleteCompany/'+companyId)
            .pipe();
    }
}